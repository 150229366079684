<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">

            <v-dialog v-model="snackbar" persistent max-width="500" >
                <v-card>
                    <v-card-text style="background-color: red" class="scrollable-content error">
                        <v-scroll-y style="max-height: 200px; max-width: 200px; overflow: hidden">
                            <div class="pt-5">
                            <p v-for="(item, index) in errorMessage" :key="index" style="color: white"> {{item}}</p>
                            </div>
                        </v-scroll-y>
                    </v-card-text>
                    <v-card-actions style="background-color: red" class="error">
                        <v-spacer></v-spacer>
                        <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <v-row class="mx-5">
                <v-col :cols="5">
                    <v-file-input
                        v-model="imports.charges.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="BP Charges"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="imports.charges.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="upload('charges')">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>

                <v-col :cols="5">
                    <v-file-input
                        v-model="imports.commissions_trail.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="BP Commissions Trail"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="imports.commissions_trail.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="upload('commissions_trail')">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="mx-5">
                <v-col :cols="5">
                    <v-file-input
                        v-model="imports.commissions.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="BP Commissions"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="imports.commissions.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="upload('commissions')">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>

                <v-col :cols="5">
                    <v-file-input
                        v-model="imports.bank.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="BP Bank"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="imports.bank.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="upload('bank')">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="mx-5">
                <v-col :cols="5">
                    <v-file-input
                        v-model="imports.email.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="BP Email"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="imports.email.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="upload('email')">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>

                <v-col :cols="5">
                    <v-file-input
                        v-model="imports.manpower.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="BP Manpower"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="imports.manpower.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="upload('manpower')">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';

export default {
    data () {
        return {
            errorMessage: [],
            acceptedFormats: '.csv',
            snackbar: false,
            initialPayload: []
        }
    },
    
    computed: {
    ...mapGetters({
            imports: 'finance/imports'
        })
    },

    async mounted () { 
        console.log(this.imports)
    },
    methods: {
        cheackStatus(res){
            console.log(res)
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Uploaded Successfully','messagetype':'success'});
                this.$store.commit('finance/SET_IMPORTS_DEFAULT')
            } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Import Validation Failures'){
                this.errorMessage = []
                this.errorMessage = res.error
                this.snackbar = true
                this.$store.commit('finance/SET_IMPORTS_DEFAULT')
            } else if ((res.status === 206 || res.code === 400 || res.code === 422)){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.$store.commit('finance/SET_IMPORTS_DEFAULT')
            } else if (res.status == 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.$store.commit('finance/SET_IMPORTS_DEFAULT')
            }  else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
                this.$store.commit('finance/SET_IMPORTS_DEFAULT')
            }
        },
        async upload(type) {

            this.initialPayload = []

            if (type == 'charges'){
                this.initialPayload = this.imports.charges
            } else if (type == 'commissions_trail'){
                this.initialPayload = this.import.commissions_trail
            } else if (type == 'commissions'){
                this.initialPayload = this.imports.commissions
            } else if (type == 'bank'){
                this.initialPayload = this.imports.bank
            } else if (type == 'email'){
                this.initialPayload = this.imports.email
            } else if (type == 'manpower'){
                this.initialPayload = this.imports.manpower
            }
            
            // Create a new FormData object
            var formData = new FormData();

            // Append the 'type' field to the FormData
            formData.append('type', type);

            var options = {
                initialFormData: formData, // Use the formData created above
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function (value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1' : '0';
                    }
                    return value;
                }
            }

            var convertedFormData = jsonToFormData(this.initialPayload, options);

            console.log(convertedFormData)
            
            const payload = { payload: convertedFormData };
            const res = await this.$store.dispatch('finance/doUploadExcelFinance', { payload });
            this.cheackStatus(res)
        },
    }
}
</script>

<style scoped>
.scrollable-content {
  max-height: 500px;
  overflow-y: auto;
}

/* Hide the scrollbar */
.scrollable-content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>